* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
section {
    padding: 100px 200px;
    width: 100%;
    min-height: 100vh;
    background: url(./bg.png) no-repeat;
    background-attachment: fixed;
    background-size: cover;   
}

.main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;

}

.main h2 {
    color: #fff;
    font-size: 1.5em;
    font-weight: 500;
}

.main h2 span {
    display: inline-block;
    margin-top: 10px;
    color: #4e9eff;
    font-size: 3em;
    font-weight: 600;
}

.main h3 {
    color: #fff;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.main-btn {
    color: #fff;
    background-color: #3a6cf4;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 600;
    display: inline-block;
    padding: 0.9375em 2.1875em;
    letter-spacing: 1px;
    border-radius: 15px;
    margin-bottom: 40px;
    transition: 0.7s ease;
    margin-right: 30px;
}

.main-btn:hover {
    background-color: #50b8e7;
    transform: scale(1.1);
}

.link{
    color: #fff;
    font-size: 1.7em;
    padding-right: 20px;
}

.link:hover{
    color: #0a66c2;
}

.twitter{
    color: #fff;
    font-size: 1.7em;
    padding-right: 20px;
}

.twitter:hover{
    color: #179cf0;
}

.github{
    color: #fff;
    font-size: 1.7em;
    padding-right: 20px;
}

.github:hover{
    color: rgb(81, 81, 81);
}

/* .title {
    font-size: 2em;
    font-weight: 300;
    text-align: center;
    color: #fff;
}
*/

@media (max-width:1023px){


    /* .title{
        font-size: 1.8em;
    } */

    section{
        padding: 80px 20px;
        width: 100%;
    }

    .main h2{
        margin-top: 20px;
        font-size: 1em;
    }

    .main-content h3{
        font-size: 1.6em;
    }

}
