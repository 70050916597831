.title {
    color: #fff;
    font-size: 2.2em;
    font-weight: 700;
    margin: 20px;
}

.Name{
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    border-radius: 5px;
    width: 25%;
    margin-left: 27em;
    background-color: #01373e00;
    border-color: #fff;
    margin-bottom: 10px;
}

.Email{
    margin-bottom: 10px;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    border-radius: 5px;
    width: 25%;
    margin-left: 27em;
    background-color: #01373e00;
    border-color: #fff;
}

.Subject{
    margin-bottom: 10px;
    color: #fff;
    font-size: 1em;
    font-weight: 500;
    border-radius: 5px;
    width: 25%;
    margin-left: 27em;
    background-color: #01373e00;
    border-color: #fff;
}

.Message{
    margin-bottom: 10px;
    color: #fff;
    font-size: 1.3em;
    font-weight: 500;
    border-radius: 5px;
    background-color: #01373e00;
    border-color: #fff;
    width: 55vh;
    margin-left: 17em;    
}

.btn{
    
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    font-size: 1em;
    font-weight: 500;
    border-radius: 5px;
    border-color: #fff;
    margin-left: 30em;
    padding: 5px;
    cursor: pointer;
}

@media (max-width:1023px){

  .title{
    font-size: 1.8em;
}

.Name{
    width: 50%;
    margin-left: 6em;
}

.Email{
    width: 50%;
    margin-left: 6em;
}
.Subject{
    width: 50%;
    margin-left: 6em;
}
.Message{
   
    width: 14em;
    margin-left: 2.5em;
}

.btn{
    width: 50%;
    margin-left: 6em;
}
}  