* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
section {
    padding: 100px 200px;
    width: 100%;
    height: 100%;
    background: url(../home/bg.png) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    
}

.title {
    color: #fff;
    font-size: 2.2em;
    font-weight: 700;
    margin: 20px;
}

.description {
    color: #fff;
    font-size: 1.3em;
    font-weight: 200;
    /* line-height: 1.5em; */
    margin-top: 20px;
}

.Description {
    color: #fff;
    font-size: 1.3em;
    font-weight: 200;
    /* line-height: 1.5em; */
    margin-top: 40px;
    margin-bottom: 40px;
    letter-spacing: 1px;
    word-spacing: 1px;
}


.img{
    width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.button{
    color: #fff;
    background-color: #3a6cf4;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: 900;
    padding: 20px;
    border-radius: 100px;
    transition: 0.7s ease;
    margin-right: 100vh;
}

.button:hover{
    background-color: #50b8e7;
    transform: scale(1.1);
}
@media (max-width:1023px){


    .title{
        font-size: 1.8em;
    }

    section{
        padding: 80px 20px;
        width: 100%;
    }

    .description{
        font-size: 1em;
    }

    .Description{
        font-size: 1.6em;
    }
    .img{
        width: 300px;
    }
    .button{
        font-size: 1em;
        margin-right: 0em;
        justify-items: left;
    }

}