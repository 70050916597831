.title {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
  }


  .image{
    width: 20em;
    
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  
  .card {
    background-color: #fff;
    width: 23em;
    border-radius: 10px;
    padding: 25px;
    margin: 10px;
    transition: 0.7s ease;
  }
  
  .card:hover {
    transform: scale(1.1);
  }
  
  .info {
    text-align: center;
  }
  
  .info-card h3 {
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
    margin: 10px;
  }
  